$color-select-file: #999;
#container-seletor-arquivo {
  // padding: 16px;
  font-size: 1rem;
  #mensagem-arquivo {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: $color-select-file;
    & > span {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    span {
      font-weight: 400;
    }
  }
  #container-arquivo-selecionado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    #arquivo-selecionado {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }
  }
}
